
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore/lite';

const firebaseConfig = {
  apiKey: "AIzaSyCvYeXU_sKoGtZw4PiEI-z5Q2CWe2EkJa0",
  authDomain: "messagesender-9e2b1.firebaseapp.com",
  projectId: "messagesender-9e2b1",
  storageBucket: "messagesender-9e2b1.appspot.com",
  messagingSenderId: "325554340512",
  appId: "1:325554340512:web:289667063e7f3cda38ffc5",
  measurementId: "G-NT4M27FLZE"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth();
const provider = new GoogleAuthProvider();
export const db = getFirestore(app);
export const storage = getStorage(app);


export const signInWithGoogle = () => signInWithPopup(auth, provider);